<template>
  <div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
    <div class="container">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-xl-5">
                <div class="product-detail">
                  <div class="tab-content" id="v-pills-tabContent">
                    <div class="tab-pane fade active show" id="product-4" role="tabpanel">
                      <div class="product-img">
                        <img
                          src="https://kmcorporate.com/wp-content/uploads/2021/06/Easy-S-tre-quarti-rev.2-210609-DEF-1024x696.jpg"
                          alt="img-4" class="img-fluid mx-auto d-block" />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end product img -->
              </div>
              <div class="col-xl-7">
                <div class="mt-4">
                  <p style="font-size: 16px; color: black; font-weight: 400">
                    Fully automatic terminating machine for IDC Connector
                  </p>
                  <h1 style="color: black; font-weight: 800" class="font-size-20 mb-3">
                    MAIN FEATURES
                  </h1>
                  <p style="font-size: 16px; color: black; font-weight: 400">
                    Feeding system: up to 3 different wires, wire length freely programmable (5 wires optional) –
                    Parallel and crossing wires as well as loops or one-side wire terminations are producible – Min wire
                    length = 195 mm (Loops), 80 mm (Free wire) – Max. wire length = 3000 mm (Loops), 1500 mm (Free wire)
                    – Simultaneous processing connectors with different number of ways, coding versions etc. – Max
                    Production configuration: 54 contact positions for Rast 2,5 28 contact positions for Rast 5 –
                    Connector loading system without machine stop – Terminating Unit with fine adjustment termination
                    height – Test device for the correct wire position in the connector – Continuity Test – Coding
                    station, full automatic setting blade – Test device of the correct key cutting – Selective
                    destroying unit of bad harness – User friendly HMI based on Windows O.S. – Internet connection for
                    remote assistance – Conveyor guide for good harnesses – Expulsion of bad harnesses – CE conformity
                  </p>
                </div>
                <br />
                <div class="mt-4">
                  <h1 style="color: black; font-weight: 800" class="font-size-20 mb-3">
                    MODULE (Option)
                  </h1>
                  <p style="font-size: 16px; color: black; font-weight: 400">
                    Max 2 Crimping units, with CFA – Wire Feeding up to 5 different wires, length freely programmable (5
                    wires optional) – Min. wire length = 80 mm ((Free / Crimped / Stripped wires) – Max. wire length =
                    1500 mm ((Free / Crimped / Stripped wires) – Carrier strip chopper – Paper take-up – Sleeve
                    Insertion Unit (optional) – Seal Station Unit (optional) – Dimensions: 1300 x 1800 x h 2450 mm
                    (safety shield open) – Weight: approx. 1500
                  </p>
                </div>
                <br />



                <p style="font-size: 14px; color: black; font-weight: 400">
                  All data and measures are indicative and can be changed without notice. Certain types of extremely
                  hard, tough wires may not be able to be processed even if written within the indicated ranges. In case
                  of doubt, KM Corporate is willing to produce samples of your wire.
                </p>
              </div>
            </div>
            <!-- end row -->
            <br />
            <br />
            <br />
            <div class="row">
              <div class="col-5"></div>
              <div class="col-7">
                <button @click="file()" class="btn btn-primary" style="width:100%"><i class="fa fa-download"></i>
                  Download The Technical Sheet</button>
              </div>
            </div>
            <br><br><br>
            <div class="row">
              <h1 style="color: black; font-weight: 800; margin-left: 40px" class="font-size-20 mb-3">
                Gallery

              </h1>

            </div>
            <br />

            <div class="row" style="margin-left:50px;">

              <vue-picture-swipe :items="items"></vue-picture-swipe>
            </div>
            <!-- end row -->

          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {

    Header,
    Footer,


  },
  data() {
    return {
      items: [{
        src: 'https://kmcorporate.com/wp-content/uploads/2021/06/Intecon-XH-dallalto-Rev.3-210610-DEF-150x150.jpg',
        thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/06/Intecon-XH-dallalto-Rev.3-210610-DEF-150x150.jpg',
        w: 1200,
        h: 900,
        alt: 'some numbers on a grey background' // optional alt attribute for thumbnail image
      },
      {
        src: 'https://kmcorporate.com/wp-content/uploads/2021/07/INTECON-XH-rev.1-210714-150x150.jpg',
        thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/07/INTECON-XH-rev.1-210714-150x150.jpg',
        w: 1200,
        h: 900
      },
      {
        src: 'https://kmcorporate.com/wp-content/uploads/2021/07/INTECON-XH-HMI-150x150.jpg',
        thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/07/INTECON-XH-HMI-150x150.jpg',
        w: 1200,
        h: 900
      },
      {
        src: 'https://kmcorporate.com/wp-content/uploads/2021/07/InteconXH-KM-012-150x150.jpg',
        thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/07/InteconXH-KM-012-150x150.jpg',
        w: 1200,
        h: 900
      },
      {
        src: 'https://kmcorporate.com/wp-content/uploads/2021/07/InteconXH-KM-013-150x150.jpg',
        thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/07/InteconXH-KM-013-150x150.jpg',
        w: 1200,
        h: 900
      },
      {
        src: 'https://kmcorporate.com/wp-content/uploads/2021/07/InteconXH-KM-017-150x150.jpg',
        thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/07/InteconXH-KM-017-150x150.jpg',
        w: 1200,
        h: 900
      }
      ]
    };

  },

  methods: {
    file() {
      window.location.href = "https://kmcorporate.com/wp-content/uploads/2021/06/Scheda_Intecon-Xh_Rev-19-01-Low.pdf";
    }
  }
};
</script>